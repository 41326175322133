import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { UPDATE_TASKS } from "@/core/services/store/tasks.module";

import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

//vue tour
//import VueTour from "vue-tour";
import ProgressBar from 'vuejs-progress-bar';
import VueEllipseProgress from 'vue-ellipse-progress';
import VueAnimateNumber from 'vue-animate-number'
import VueCircle from 'vue2-circle-progress';

import VueApexCharts from 'vue-apexcharts'

// Css removed from nod_modules and put in the assets folder and linked in the index.html OS 29-11-2022
//require("vue-tour/dist/vue-tour.css");
//Vue.use(VueTour);
Vue.use(ProgressBar);
Vue.use(VueCircle);
Vue.use(VueEllipseProgress);
Vue.use(VueAnimateNumber)
Vue.use(VueApexCharts);

import { FormSelectPlugin } from 'bootstrap-vue';
Vue.use(FormSelectPlugin);

Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.component('apexchart', VueApexCharts);

// global variable
// Vue.prototype.$dashboard_tour = true;
// Vue.prototype.$reviewdata_heatmap = true;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Remove this to disable mock API
//import MockService from "@/core/mock/mock.service";
//MockService.init();


router.beforeEach((to, from, next) => {
    // const token = router.app.$session.exists('userProfile');
    // console.log('token status');
    // console.log(token);
    // Ensure we checked auth before each page load.

    //console.log('to ' + JSON.stringify(to, null, 4));
    //console.log('from ' + JSON.stringify(from, null, 4));
    //console.log('next ' + next);
    // console.log('navigator');
    // console.log(navigator.userAgent);

    // if (navigator.userAgent.match(/Android/i) ||
    //     navigator.userAgent.match(/webOS/i) ||
    //     navigator.userAgent.match(/iPhone/i) ||
    //     navigator.userAgent.match(/iPad/i) ||
    //     navigator.userAgent.match(/iPod/i) ||
    //     navigator.userAgent.match(/BlackBerry/i) ||
    //     navigator.userAgent.match(/Windows Phone/i)) {
    //     next('#/404');
    // } else {
    Promise.all([
        store.dispatch(VERIFY_AUTH),
        store.dispatch(UPDATE_TASKS, { filter: "" })
    ]).then(next);
    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);
    // }

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");